<template>
	<div class="shop-mall">
		<router-link class="search-input" tag="div" to="/ShopMall/search">
			<div class="input1">请输入商品名称</div>
		</router-link>

		<div class="main">
			<div class="classify-box">
				<div class="classify-list">
					<div :class="['classify-link', {'ac': cateTab == 0}]" @click="cateTab = 0">精选推荐</div>
					<div :class="['classify-link', {'ac': cateTab == item.cate_id}]" v-for="(item, index) in cateList"
						:key="index" @click="cateTab = item.cate_id">{{ item.cate_name }}</div>
				</div>
			</div>

			<!-- 商品列表 -->
			<div class="goods-list">
				<goods-list :recommends="goodsList" :finished="loadLastText" :isShow="isShow" :goodsTab="3"
					listType="classify" @getMore="getMore" ref="goodsList"></goods-list>
			</div>
		</div>
		<!-- 客服组件 -->
		<customer-service></customer-service>
	</div>
</template>

<script>
	import {
		getGoods,
		getGoodsListsByCate,
		getGoodsListsByRecomm
	} from "@/http/api";
	import CustomerService from "@/views/home/children/CustomerService";
	import Tabbar from "@/components/Tabbar";
	import GoodsList from "@/components/goods/GoodsList";

	export default {
		components: {
			CustomerService,
			Tabbar,
			GoodsList
		},
		data() {
			return {
				selectedId: "2",
				cateTab: 0,
				cateList: [],
				pages: 1,
				goodsList: [],
				loadLastText: false,
				isShow: false
			};
		},
		mounted() {
			this.getGoods();
			
			if (this.$route.query.id) {
				this.cateTab = this.$route.query.id;
			} else {
				this.getGoodsListsByRecomm();
			}
		},
		methods: {
			// 获取分类列表
			async getGoods() {
				const res = await getGoods({});
				if (res.code == 200) {
					this.cateList = res.data;
				}
			},
			// 获取推荐商品列表
			async getGoodsListsByRecomm() {
				const res = await getGoodsListsByRecomm({
					pages: this.pages
				});
				this.isShow = true;
				this.$refs.goodsList.changeLoading(false);
				if (res.code == "200") {
					this.goodsList = this.goodsList.concat(res.data);
					if (res.data.length < res.numPage) {
						this.loadLastText = true;
					} else {
						this.loadLastText = false;
					}
				}
			},
			
			// 获取商品列表
			async getGoodsListsByCate() {
				const res = await getGoodsListsByCate({
					data: {
						pages: this.pages,
						cate_id: this.cateTab
					}
				});
				this.isShow = true;
				this.$refs.goodsList.changeLoading(false);
				if (res.code == "200") {
					if (this.pages == 1) {
						this.goodsList = res.data;
					} else {
						this.goodsList = this.goodsList.concat(res.data);
					}
					if (res.data.length < res.numPage) {
						this.loadLastText = true;
					} else {
						this.loadLastText = false;
					}
				}
			},
			
			// 加载更多
			getMore() {
				let cateTab = this.cateTab;
				if (!this.loadLastText) {
					this.pages++;
					if (cateTab == 0) {
						this.getGoodsListsByRecomm();
					} else {
						this.getGoodsListsByCate();
					}
				}
			}
		},
		watch: {
			cateTab(newV, oldV) {
				if (newV == 0) {
					this.pages = 1;
					this.goodsList = [];
					this.getGoodsListsByRecomm();
				} else {
					this.pages = 1;
					this.goodsList = [];
					this.getGoodsListsByCate();
				}
			}
		},
		computed: {},
		filters: {}
	};
</script>

<style lang="scss" scoped>
	.shop-mall {
		box-sizing: border-box;
		background: #f6f6f6;
		min-height: 100vh;

		* {
			box-sizing: border-box;
		}

		.search-input {
			background: #fff;
			// width: 100%;
			// height: 1.12rem;
			// border-bottom: 1px solid #e7e7e7;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.26rem 0.24rem;

			.input1 {
				// width: calc(100% - 0.8rem);
				width: 100%;
				height: 0.65rem;
				line-height: 0.65rem;
				background: #f2f2f2;
				border-radius: 0.33rem;
				font-size: 0.28rem;
				color: #b3b3b3;
				padding-left: 0.77rem;
				box-sizing: border-box;
				background-image: url("../../assets/serch.png");
				background-repeat: no-repeat;
				background-size: 0.25rem 0.25rem;
				background-position: 0.3rem center;
			}

			p {
				font-size: 0.3rem;
				width: 0.8rem;
				text-align: right;
			}
		}

		.main {
			width: 100vw;
			display: flex;
			justify-content: space-between;
			height: calc(100vh - 1.17rem);

			.classify-box {
				overflow-y: scroll;
				min-width: 21.2vw;
				height: 100%;
				background-color: #fff;

				.classify-link {
					width: 21.2vw;
					height: 55px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #fff;
					font-size: 0.26rem;
					color: #737371;
					position: relative;
				}

				.classify-link.ac {
					color: #e40011;
					background: #f6f6f6;
				}

				.classify-link.ac::before {
					content: "";
					height: 55px;
					width: 0.05rem;
					background: #e40011;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.goods-list {
				// padding: 3vw;
				width: calc(100vw - 21.2vw);
				height: 100%;
				overflow-y: auto;

				.goods-grid {
					width: 100%;
					// height: 28vw;
					// display: flex;
					// align-items: center;
					// justify-content: space-between;
					// padding: 0 5px;
					// border-radius: 5px;
					background: #fff;
					overflow: hidden;
					margin-bottom: 0.2rem;

					img {
						width: 100%;
						height: 3.9rem;
						object-fit: cover;
					}

					.goods-info {
						width: 100%;
						padding: 0.24rem;
						box-sizing: border-box;
						// height: 24vw;
						// display: flex;
						// flex-direction: column;
						// justify-content: space-between;
					}

					.goods-name {
						font-size: 0.28rem;
						line-height: 1.4;
						color: #323233;
						border-bottom: #e9eaeb solid 1px;
						padding-bottom: 0.24rem;
						margin-bottom: 0.24rem;
					}

					.return {
						height: 0.4rem;
						width: 7em;
						font-size: 0.26rem;
						background: #ff6300;
						color: #fff;
						padding: 0 0.1rem;
						border-radius: 0.4rem;
						display: inline;
						text-align: center;
					}

					.price-row {
						font-size: 0.24rem;
						display: flex;
						align-items: flex-end;
						line-height: 1;

						.price-now {
							color: #e50012;

							span {
								font-size: 0.36rem;
								font-weight: bold;
							}
						}

						.price-old {
							text-decoration: line-through;
							color: #999999;
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
</style>
